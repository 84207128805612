


































import { TranslateResult } from "vue-i18n";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class PhoneForm extends Vue {
  phone = "";
  loading = false;
  errorMsg: string | TranslateResult = "";

  async nextStep(): Promise<void> {
    this.loading = true;
    let res;
    try {
      await this.$store.dispatch("register/setPhone", this.phone);
      res = await this.$store.dispatch("register/validatePhone");
      if (res?.result === "VALID") {
        this.$router.push({ name: "b-sms-step" });
      } else if (res?.result === "INVALID_FORMAT") {
        this.errorMsg = this.$t("SignUp_Phone_Format_Error");
        this.$track({ label: "bsignup_inputphone_error_invalidphone" });
      } else if (res?.result === "DUPLICATE") {
        this.errorMsg = this.$t("SignUp_Phone_Duplication_Error");
        this.$track({ label: "signup_inputphone_error_phoneexists" });
      }
    } catch (e) {
      console.error(e);
      this.errorMsg = this.$t("General_NetworkError_Message");
    }
    this.loading = false;
  }

  mounted(): void {
    if (this.$store.state.register.phone) {
      this.phone = this.$store.state.register.phone;
    }
  }
}
